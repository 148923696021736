<template>
    <div>
        <van-list v-show="showList"
                  v-model="loading"
                  :finished="finished"
                  :finished-text="finishedText"
                  :immediate-check="false"
                  @load="onLoad">
            <div class="main_list" v-for="(item,index) in list" :key="index">
                <div class="main_list_bottom">
                    <div class="main_list_info">
                        <div class="main_list_content">
                            <span>【目的地】{{ item.projectName }}</span>
                            <span class="main_list_type">
                                {{ item.status == 1 ? '待审核' : item.status == 2 ? '已通过' :
                                item.status == 3 ?  '已驳回' : ''}}</span>
                        </div>
                        <div class="main_list_xinxi">
                            <div>申请时间：{{ item.createTime ? item.createTime : ''}}</div>
                            <div>起始时间：{{ item.startTime ? item.startTime : ''}}</div>
                            <div>截止时间：{{item.endTime ? item.endTime : ''}}</div>
                            <div>被拜访人：{{ item.visiteeName ? item.visiteeName : '' }}</div>
                            <div>拜访事由：{{ item.visitReason ? item.visitReason : '' }}</div>
                            <div v-show="item.status == 3" style="color: red;">驳回原因：{{ item.rejectReason ?
                                item.rejectReason : '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <div v-show="showInfo">
            <div class="empty-info">
                <img src="../assets/img/empty.png">
                <p>暂无访客列表信息</p>
                <span>请先登记信息</span>
            </div>
            <div class="bottom-style-text">
                <span class="p-text2">Copyright@2023 All Rights Reserved.</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {List, PullRefresh, Toast} from 'vant'
    import {getVisitorList} from "../api/interface";

    export default {
        name: "visitorApplicationRecord",
        components: {
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
        },
        data() {
            return {
                list: [],
                finished: false,
                error: false,
                loading: false,
                total: 0,
                finishedText: "没有更多了",
                loadingText: "加载中",
                current: 1,
                size: 10,
                showList: false,
                showInfo: false
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                let param = {
                    current: this.current,
                    size: this.size,
                }
                getVisitorList(param).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        // 这里将拿到的数组合并到老数组，不能直接赋值，必须是合并
                        this.list = this.list.concat(res.data.data.records);
                        // 赋值当前页、总页数、总数量
                        this.current = res.data.data.current;
                        this.total = res.data.data.total;
                        this.size = res.data.data.size;

                        // 取消loading效果
                        this.loading = false;

                        // 如果当前数组的长度超过总信息条数，不再触发滚动
                        if (this.list.length >= this.total) {
                            // 加载结束，不再触发滚动加载
                            this.finished = true;
                        }
                        if (this.list.length > 0) {
                            this.showInfo = false;
                            this.showList = true;
                        } else {
                            this.showInfo = true;
                            this.showList = false;
                        }
                    }
                })
            },
            onLoad() {
                this.current++;
                this.fetchData();
            },
            onRefresh() {
                this.fetchData();
            },
            onHomePage() {
                this.$router.push('/visitor')
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#FFF')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .head-title {
        background: linear-gradient(-30deg, #e25744, #e86f50);
        color: #FFFFFF;
        font-size: 16px;
        padding: 10px 20px;
    }

    .empty-info {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
    }

    .empty-info img {
        width: 100px;
    }

    .empty-info span {
        color: #8a8a8a;
    }

    .bottom-style-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4%;
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 12px;
        color: #666666;
    }


    .main_list {
        background-color: #F9F9F9;
        margin: 10px;
        border-radius: 10px;
    }

    .main_list_bottom {
        display: flex;
        line-height: 30px;
    }

    .main_list_info {
        width: 100%;
        font-size: 18px;
        display: flex;
        flex-direction: column;
    }

    .main_list_content {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        background-color: #c7c7c738;
        padding: 3px 0;
    }

    .main_list_content span {
        margin-left: 10px;
        font-weight: bold;
        font-family: "微软雅黑 Light";
    }

    .main_list_type {
        margin-right: 20px;
    }

    .main_list_xinxi {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 10px 15px;
    }

    .main_list_xinxi div {
        font-size: 14px;
    }
</style>
